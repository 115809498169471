<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid" v-if="postulaciones !== null">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11">
                    <h5 class="card-title">
                      <b>{{ postulaciones.empresa.razon_social }}</b> |
                      <b>Fecha:</b>
                      {{ postulaciones.solicitud_diaria.fecha }} |
                      <b>Sitio:</b>
                      {{ postulaciones.solicitud_diaria.sitio.nombre }}
                    </h5>
                  </div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        class="btn btn-sm bg-navy"
                        @click="saveEstado()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.postulaciones.edit'
                          ) && postulaciones.estado == 1
                        "
                      >
                        <i class="fas fa-flag-checkered"></i>
                        <br />Finalizar
                      </button>
                      <button
                        type="button"
                        class="btn bg-primary"
                        @click="enviarCorreo()"
                        v-if="
                          postulaciones.estado == 2 &&
                            $store.getters.can(
                              'hidrocarburos.postulaciones.enviarCorreo'
                            )
                        "
                      >
                        <i class="fas fa-envelope"></i>
                        <br />EnviarCorreo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="progress progress-xs progress-striped active"
                style="height: 12px"
              >
                <div
                  class="
                    progress-bar progress-bar-striped progress-bar-animated
                  "
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="'width:' + porcentaje + '%'"
                >
                  {{ porcentaje }}%
                </div>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  v-if="
                    postulaciones.solicitud_diaria.det_solicitudes_diarias
                      .length > 0
                  "
                >
                  <div
                    class="col-md-12"
                    v-for="postulacion in postulaciones.solicitud_diaria
                      .det_solicitudes_diarias"
                    :key="postulacion.id"
                  >
                    <!-- <pre>{{postulaciones}}</pre> -->
                    <div
                      class="card card-outline collapsed-card"
                      :class="
                        (postulacion.det_postulaciones.length * 100) /
                          postulacion.cargues <=
                        40
                          ? 'card-danger'
                          : (postulacion.det_postulaciones.length * 100) /
                              postulacion.cargues >
                              40 &&
                            (postulacion.det_postulaciones.length * 100) /
                              postulacion.cargues <=
                              99
                          ? 'card-warning'
                          : postulacion.det_postulaciones.length ==
                            postulacion.cargues
                          ? 'card-success'
                          : 'card-secondary'
                      "
                    >
                      <div class="card-header pt-2 pb-2">
                        <h3 class="card-title">
                          <span class="badge badge-primary">
                            {{ postulacion.cita }}
                          </span>
                          <small class="ml-2">
                            <b>Ruta:</b>
                            {{ postulacion.tipo_ruta == 1 ? "Corta" : "Larga" }}
                          </small>
                          |
                          <small>
                            <b>Mercancia:</b>
                            {{ postulacion.producto.nombre }}
                          </small>
                          |
                          <small>
                            <b>Vehículo:</b>
                            {{ postulacion.tipo_vehiculo.nombre }}
                          </small>
                          |
                          <small>
                            <b>Tipo Solicitud:</b>
                            {{ postulacion.adicional ? "Adicional" : "Pleno" }}
                          </small>
                          |
                          <span class="badge badge-warning">{{
                            postulacion.cargues
                          }}</span>
                          -
                          <span class="badge badge-success">{{
                            postulacion.det_postulaciones.length
                          }}</span>
                          =
                          <span class="badge badge-info">
                            {{
                              parseInt(postulacion.cargues) -
                                parseInt(postulacion.det_postulaciones.length)
                            }}
                          </span>
                        </h3>
                        <div class="card-tools">
                          <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            data-toggle="tooltip"
                            title="Collapse"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>

                      <div class="card-body" style="display: none">
                        <div
                          class="alert alert-warning"
                          v-if="postulacion.producto.id == 10"
                        >
                          <strong>¡Atención!</strong> Todo vehículo programado
                          para cargar <strong>Fuel Oil </strong>
                          debe presentarse con el tanque lavado y sin presencia
                          de residuos.
                        </div>
                        <table
                          class="
                            table
                            table-bordered
                            table-striped
                            table-hover
                            table-sm
                          "
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th style="width: 20%">Vehículo</th>
                              <th style="width: 20%">Remolque</th>
                              <th>Conductor</th>
                              <th>Estado</th>
                              <th>Orden Cargue</th>
                              <th>Adjunto</th>
                              <th>Acción</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(det, detIndex) in det_postulaciones.find(
                                (post) =>
                                  post.id_det_solicitud === postulacion.id
                              ).data"
                              :key="detIndex"
                            >
                              <td>{{ detIndex + 1 }}</td>
                              <td>
                                <div
                                  v-if="
                                    idActualizar == detIndex &&
                                      det.det_solicitud_diaria_id ==
                                        formActualizar.det_solicitud_diaria_id
                                  "
                                >
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="formActualizar.vehiculo.placa"
                                    @change="
                                      buscarVehiculos(
                                        postulacion.tipo_ruta,
                                        postulacion.tipo_vehiculo.id
                                      )
                                    "
                                    :class="
                                      $v.formActualizar.vehiculo.id.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                </div>
                                <span v-else>{{ det.vehiculo.placa }}</span>
                              </td>
                              <td>
                                <div
                                  v-if="
                                    postulacion.tipo_vehiculo_id == 1 &&
                                      det.remolque != null
                                  "
                                >
                                  <div
                                    v-if="
                                      idActualizar == detIndex &&
                                        det.det_solicitud_diaria_id ==
                                          formActualizar.det_solicitud_diaria_id
                                    "
                                  >
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      v-model="formActualizar.remolque.placa"
                                      @change="buscarRemolques()"
                                      v-if="postulacion.tipo_vehiculo_id == 1"
                                      :class="
                                        $v.formActualizar.remolque.id.$invalid
                                          ? 'is-invalid'
                                          : 'is-valid'
                                      "
                                    />
                                  </div>
                                  <span v-else>{{ det.remolque.placa }}</span>
                                </div>
                              </td>
                              <td>
                                <div
                                  v-if="
                                    idActualizar == detIndex &&
                                      det.det_solicitud_diaria_id ==
                                        formActualizar.det_solicitud_diaria_id
                                  "
                                >
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="
                                      formActualizar.conductor.numero_documento
                                    "
                                    @change="buscarConductores()"
                                    :class="
                                      $v.formActualizar.conductor.id.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                  {{ formActualizar.conductor.nombres }}
                                  {{ formActualizar.conductor.apellidos }}
                                </div>
                                <span v-else>
                                  {{ det.conductor.numero_documento }}
                                  -
                                  {{ det.conductor.nombres }}
                                  {{ det.conductor.apellidos }}
                                </span>
                              </td>
                              <td>
                                <div>{{ det.nEstado }}</div>
                              </td>
                              <td>
                                <div
                                  v-if="
                                    idActualizar == detIndex &&
                                      det.det_solicitud_diaria_id ==
                                        formActualizar.det_solicitud_diaria_id
                                  "
                                >
                                  <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="formActualizar.orden_cargue"
                                    :class="
                                      $v.formActualizar.orden_cargue.$invalid
                                        ? 'is-invalid'
                                        : 'is-valid'
                                    "
                                  />
                                </div>
                                <span v-else>{{ det.orden_cargue }}</span>
                              </td>
                              <td>
                                <div
                                  v-if="
                                    idActualizar == detIndex &&
                                      det.det_solicitud_diaria_id ==
                                        formActualizar.det_solicitud_diaria_id
                                  "
                                >
                                  <div v-if="det.link_orden_cargue">
                                    <button
                                      type="button"
                                      class="btn btn-sm bg-warning"
                                      @click="eliminarFoto()"
                                    >
                                      <i class="fas fa-trash-alt"></i>
                                    </button>
                                  </div>
                                  <div v-else>
                                    <div v-if="file === null">
                                      <input
                                        type="file"
                                        id="file"
                                        ref="file"
                                        class="form-control-file"
                                        accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                                        @change="elegirDirectorio()"
                                      />
                                    </div>
                                    <div v-else>
                                      <div class="btn-group float-right">
                                        <button
                                          type="button"
                                          class="btn btn-sm bg-warning"
                                          @click="borrarFotoParcial()"
                                        >
                                          <i class="fas fa-trash-alt"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <span v-else>
                                  <div v-if="det.link_orden_cargue">
                                    <button
                                      type="button"
                                      class="btn btn-sm bg-gray-dark"
                                      @click="getImage(det.link_orden_cargue)"
                                    >
                                      <i class="fas fa-image"></i>
                                    </button>
                                  </div>
                                  <div v-else>No hay</div>
                                </span>
                              </td>
                              <td v-if="falgValidando">
                                <div
                                  class="spinner-border text-dark"
                                  role="status"
                                ></div>
                              </td>
                              <td style="width: 50px" class="text-right" v-else>
                                <div
                                  class="btn-group float-right"
                                  v-if="
                                    idActualizar == detIndex &&
                                      det.det_solicitud_diaria_id ==
                                        formActualizar.det_solicitud_diaria_id
                                  "
                                >
                                  <!-- Adiciona postulación -->
                                  <button
                                    type="button"
                                    class="btn btn-sm bg-success"
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.postulaciones.edit'
                                      ) &&
                                        !$v.formActualizar.$invalid &&
                                        file !== null
                                    "
                                    @click="arreglarForm(det, detIndex)"
                                  >
                                    <i class="fas fa-check-circle"></i>
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-sm bg-danger"
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.postulaciones.edit'
                                      ) &&
                                        !$v.formActualizar.$invalid &&
                                        file !== null
                                    "
                                    @click="limpiarForm()"
                                  >
                                    <i class="fas fa-times-circle"></i>
                                  </button>
                                  <!-- Elimina postulación -->
                                </div>
                                <div class="btn-group float-right" v-else>
                                  <!-- Habilita edición -->

                                  <button
                                    type="button"
                                    class="btn btn-sm bg-navy"
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.postulaciones.edit'
                                      ) &&
                                        (new Date(hoy) <
                                          new Date(
                                            `${postulaciones.solicitud_diaria.fecha} ${postulacion.cita}`
                                          ) ||
                                          $store.getters.can(
                                            'hidrocarburos.postulaciones.editSU'
                                          ))
                                    "
                                    @click="
                                      verFormActualizar(
                                        detIndex,
                                        det,
                                        postulacion
                                      )
                                    "
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-sm btn-danger"
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.postulaciones.delete'
                                      ) &&
                                        det.id != null &&
                                        !(
                                          new Date() >
                                          new Date(
                                            `${postulaciones.solicitud_diaria.fecha} ${postulacion.cita}`
                                          )
                                        ) &&
                                        postulaciones.estado == 1
                                    "
                                    @click="destroy(det)"
                                  >
                                    <i class="fas fa-trash-alt"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="alert alert-warning" v-else>
                  <h5>
                    <i class="icon fas fa-exclamation-triangle"></i>
                    Atención!
                  </h5>
                  No se encontraron postulaciones.
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";

export default {
  name: "PostulacionForm",
  components: {
    Loading,
  },
  data() {
    return {
      porcentaje: null,
      cargando: false,
      pendientes: 0,
      idSolicitud: null,
      postulaciones: null,
      vigente: 0,
      file: null,
      formActualizar: {},
      idActualizar: null,
      distancia: 0,
      empresa_id: null,
      det_postulaciones: [],
      falgValidando: false,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      hoy: null,
      manana: null,
    };
  },
  validations() {
    if (this.formActualizar.tipo_vehiculo_id == 1) {
      return {
        formActualizar: {
          vehiculo: {
            id: {
              required,
            },
          },
          remolque: {
            id: {
              required,
            },
          },
          conductor: {
            id: {
              required,
            },
          },
          orden_cargue: {
            required,
          },
        },
      };
    } else {
      return {
        formActualizar: {
          vehiculo: {
            id: {
              required,
            },
          },
          remolque: {
            id: {},
          },
          conductor: {
            id: {
              required,
            },
          },
          orden_cargue: {
            required,
          },
        },
      };
    }
  },
  methods: {
    borrarFotoParcial() {
      this.file = null;
    },
    getIndex() {
      this.cargando = true;
      this.idSolicitud = this.$route.params.solicitud_diaria_id;
      this.idSolicitudEmpresa = this.$route.params.solicitud_diaria_empresa_id;
      this.empresa_id = this.$route.params.empresa_id;
      axios
        .get("/api/hidrocarburos/postulaciones/show/" + this.idSolicitud, {
          params: {
            empresa_id: this.empresa_id,
          },
        })
        .then(async (response) => {
          this.cargando = false;
          this.postulaciones = response.data[0];
          await this.getPostulaciones(response.data[0]);
          await this.barraPorcentaje();
        });
    },

    barraPorcentaje() {
      // Calculo de la barra de progreso
      let contador = 0;
      this.postulaciones.solicitud_diaria.det_solicitudes_diarias.forEach(
        (postu) => {
          contador += parseInt(postu.det_postulaciones.length);
          this.pendientes +=
            parseInt(postu.cargues) - parseInt(postu.det_postulaciones.length);
        }
      );

      let carguesDet = this.postulaciones.solicitud_diaria.det_solicitudes_diarias.reduce(
        (acumulador, detSol) => {
          // Verifica si la propiedad cargues existe y es un número
          if ("cargues" in detSol && !isNaN(detSol.cargues)) {
            // Suma el valor de la propiedad cargues al acumulador
            return acumulador + detSol.cargues;
          }
          // Si no existe la propiedad cargues o su valor no es un número, devuelve el acumulador sin cambios
          return acumulador;
        },
        0
      );

      this.porcentaje =
        (contador /
          parseInt(
            carguesDet
          )) /*  +
            parseInt(this.postulaciones.cargues_adicional) */ *
        100;
    },

    getPostulaciones(postulaciones) {
      var det_postulacion = new Array();
      postulaciones.solicitud_diaria.det_solicitudes_diarias.forEach(
        (detSolicitud) => {
          let trio_post = new Array();
          for (let i = 0; i < detSolicitud.cargues; i++) {
            let datospost = {
              id: null,
              det_solicitud_diaria_id: detSolicitud.id,
              vehiculo: {
                id: "",
                placa: "",
              },
              remolque: {
                id: "",
                placa: "",
              },
              conductor: {
                id: "",
                numero_documento: "",
                nombres: "",
                apellidos: "",
              },
              orden_cargue: "",
              tipo_vehiculo_id: detSolicitud.tipo_vehiculo_id,
              adicional: detSolicitud.adicional,
            };
            if (detSolicitud.det_postulaciones.length > i) {
              datospost = detSolicitud.det_postulaciones[i];
            }

            trio_post.push(datospost);
          }
          let arr_fin_trio_post = {
            id_det_solicitud: detSolicitud.id,
            data: trio_post,
          };
          det_postulacion.push(arr_fin_trio_post);
        }
      );
      this.det_postulaciones = det_postulacion;
    },

    // Se valida si está a tiempo de realizar postulaciones
    async validarVigencia(det) {
      this.vigente = 0;
      const fecha = new Date(this.postulaciones.solicitud_diaria.fecha);
      fecha.setDate(fecha.getDate() + 1);

      let hoy = null;
      let manana = null;

      await axios.get("/api/fecha").then(async (response) => {
        //console.log(response.data);
        hoy = new Date(response.data.hoy);
        manana = new Date(response.data.manana);
      });

      /* console.log(fecha, hoy);
      console.log(manana); */

      //manana.setDate(manana.getDate() + 1);

      if (fecha.getDate() == manana.getDate()) {
        // Se valida la hora permitida de postulaciones
        const hora_validar = hoy.getHours();
        if (hora_validar < 24) {
          this.vigente = 1;
        } else {
          let cita = this.postulaciones.solicitud_diaria.det_solicitudes_diarias.find(
            (post) => post.id === det.det_solicitud_diaria_id
          ).cita;
          let fecha_hora_cita =
            this.postulaciones.solicitud_diaria.fecha + " " + cita;
          let resvaltiempo = await this.validarTiempo(
            fecha_hora_cita,
            this.postulaciones.solicitud_diaria.sitio.tiempo_maximo_cambio,
            hoy
          );
          if (resvaltiempo) {
            this.vigente = 1;
          } else {
            this.vigente = 2;
          }
        }
      } else if (fecha.getDate() > manana.getDate()) {
        this.vigente = 1;
      } else if (fecha.getDate() == hoy.getDate()) {
        let cita = this.postulaciones.solicitud_diaria.det_solicitudes_diarias.find(
          (post) => post.id === det.det_solicitud_diaria_id
        ).cita;
        let fecha_hora_cita =
          this.postulaciones.solicitud_diaria.fecha + " " + cita;
        //console.log(fecha_hora_cita);
        let resvaltiempo = await this.validarTiempo(
          fecha_hora_cita,
          this.postulaciones.solicitud_diaria.sitio.tiempo_maximo_cambio,
          hoy
        );
        //console.log(resvaltiempo);
        if (resvaltiempo) {
          this.vigente = 1;
        } else {
          if (det.adicional == 1) {
            this.vigente = 1;
          } else {
            this.vigente = 0;
          }
        }
      }

      if (this.$store.getters.can("hidrocarburos.postulaciones.editSU")) {
        this.vigente = 1;
      }
      return this.vigente;
    },

    // Se valida si está a tiempo de realizar ediciones de postulaciones
    validarTiempo(fecha_hora_cita, tiempo_maximo_cambio, hoy) {
      const ahora = hoy;
      const cita = new Date(fecha_hora_cita);
      let diffMs = cita - ahora;
      let diffMins = Math.round(diffMs / 60000);
      //console.log(diffMins);
      if (diffMins > tiempo_maximo_cambio) {
        return true;
      }
      return false;
    },

    saveEstado() {
      this.$swal({
        title: "Está seguro de terminar estas postulaciones?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Terminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          let estado = 2;
          let form = {
            id: this.postulaciones.id,
            estado,
          };
          axios({
            method: "PUT",
            url: "/api/hidrocarburos/postulaciones/cambiaEstadoDetSolicitudEmp",
            data: form,
          })
            .then(() => {
              this.postulaciones.estado = 2;
              this.$swal({
                icon: "success",
                title: "La postulación se guardó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.cargando = false;
            });
        }
      });
    },

    getImage(url) {
      window.open(this.uri_docs + url, "_blank");
    },

    eliminarFoto() {
      this.formActualizar.link_orden_cargue = null;
    },

    async buscarVehiculos(tipo_ruta, tipo_vehiculo) {
      this.falgValidando = true;
      let dato = this.formActualizar.vehiculo.placa;
      let res = await this.validarStatus(dato, "V");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.falgValidando = false;
          await this.msgDocumentacion(res);
          /*  if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) { */
          this.formActualizar.vehiculo = {};
          return false;
          //}
        }

        if (res.viaje.length > 0) {
          this.formActualizar.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        //Validar si ya se encuentra con una postulacion
        if (res.postulacion.length > 0) {
          this.formActualizar.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        //Validar si ya se encuentra con una postulacion adicionales
        if (res.postulacion_adicionales.length > 0) {
          this.formActualizar.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        //Validar si el vehículo tiene peso asignado.
        if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.formActualizar.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        if (res["info"] == false) {
          this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Vehiculos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formActualizar.vehiculo = {};
          return false;
        }
        if (!res["has_lineas"]) {
          this.$swal({
            icon: "error",
            title: `El vehiculo no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formActualizar.vehiculo = {};
          return false;
        }
        if (!res["linea_gnv"]) {
          this.$swal({
            icon: "error",
            title: `El vehículo tiene combustible a GAS pero no se ha asignado línea vehicular`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formActualizar.vehiculo = {};
          return false;
        }
        //Valida el tipo de vehiculo
        if (res[0].tipo_vehiculo_id != tipo_vehiculo) {
          this.formActualizar.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El tipo de vehículo no coincide.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }
        //Valida Si la Empresa del vehiculo tiene check gnv
        if (res[0].tipo_combustible == 1) {
          if (res[0].empresa.gnv == null || res[0].empresa.gnv == 0) {
            this.formActualizar.vehiculo = {};
            this.$swal({
              icon: "error",
              title:
                "La Empresa del vehículo no se encuentra habilitada para Gnv.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          }
        }

        //Valida el modelo para el trayecto de la ruta larga
        if (tipo_ruta == 2) {
          if (res.modelo) {
            this.formActualizar.vehiculo = res[0];
          } else {
            this.formActualizar.vehiculo = {};
            this.$swal({
              icon: "error",
              title:
                "El vehículo no cumple con las condiciones para ruta larga",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          }
        }

        //Valida reporte satelital del vehiculo
        if (res.ultimoRegistro) {
          if (res.ultimoRegistro.error) {
            this.formActualizar.vehiculo = {};
            this.$swal({
              icon: "error",
              title: `Error GPS: ${res.ultimoRegistro.error.msg}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          } else if (res.ultimoRegistro.success) {
            this.formActualizar.vehiculo = res[0];
            this.$swal({
              icon: "success",
              title: `${res.ultimoRegistro.success.msg} Distancia: ${res.ultimoRegistro.success.distance} Km`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return true;
          }
        }
      } else {
        this.$swal({
          icon: "error",
          title:
            "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.falgValidando = false;
        this.formActualizar.vehiculo = {};
        return false;
      }
      this.falgValidando = false;
    },

    async buscarRemolques() {
      this.falgValidando = true;
      let dato = this.formActualizar.remolque
        ? this.formActualizar.remolque.placa
        : null;
      let res = await this.validarStatus(dato, "R");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.falgValidando = false;
          /* if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) { */
          this.formActualizar.remolque = {};
          return false;
          //}
        }

        if (res["info"] == false) {
          this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Relmoques`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formActualizar.remolque = {};
          return false;
        }
        if (!res["has_lineas"]) {
          this.$swal({
            icon: "error",
            title: `El Remolque no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formActualizar.vehiculo = {};
          return false;
        }

        if (res.viaje.length > 0) {
          this.formActualizar.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else if (res.postulacion.length > 0) {
          this.formActualizar.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          //Validar si el rermolque esta en una postulacion de adicionales.
        } else if (res.postulacion_adicionales.length > 0) {
          this.formActualizar.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        } //Validar si el rermolque tiene peso asignado.
        else if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.formActualizar.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else {
          this.formActualizar.remolque = res[0];
        }
      } else {
        this.$swal({
          icon: "error",
          title:
            "El remolque digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.formActualizar.remolque = {};
      }

      this.falgValidando = false;
    },

    async buscarConductores() {
      this.falgValidando = true;
      let dato = this.formActualizar.conductor.numero_documento;
      let res = await this.validarStatus(dato, "C");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.falgValidando = false;
          /*  if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) { */
          this.formActualizar.conductor = {};
          return false;
          //}
        }
        if (res["info"] == false) {
          this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formActualizar.conductor = {};
          return false;
        }
        if (this.formActualizar.vehiculo.tipo_combustible == 1) {
          if (res[0].gnv == null || res[0].gnv == 0) {
            this.$swal({
              icon: "error",
              title:
                "La Empresa del conductor no se encuentra habilitada para Gnv.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.formActualizar.conductor = {};
            return false;
          }
        }
        if (!res["has_lineas"]) {
          this.$swal({
            icon: "error",
            title: `El Conductor no se encuentra en la linea de negocio Hidrocarburos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formActualizar.conductor = {};
          return false;
        }

        if (res.viaje.length > 0) {
          this.formActualizar.conductor = {};
          this.$swal({
            icon: "error",
            title: `El conductor se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else if (res.postulacion.length > 0) {
          this.formActualizar.conductor = {};
          this.$swal({
            icon: "error",
            title: `El conductor cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else if (res.postulacion_adicionales.length > 0) {
          this.formActualizar.conductor = {};
          this.$swal({
            icon: "error",
            title: `El conductor ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        } else {
          this.formActualizar.conductor = res[0];
        }
      } else {
        this.$swal({
          icon: "error",
          title:
            "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.formActualizar.conductor = {};
      }
      this.falgValidando = false;
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos !== undefined) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
          erroHtml += "</div>";
          await this.$swal({
            html: erroHtml,
            showCancelButton: false,
            focusConfirm: true,
            icon: "error",
            title: "Aviso en documentacion",
            showConfirmButton: false,
            showCloseButton: true,
            footer:
              "<small style='text-align:center'><b>Desde el 1 de abril se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas informacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
          });
        } else {
          this.$swal({
            icon: "error",
            title: res.validacion.error.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },

    async validarStatus(dato, tipo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/hidrocarburos/postulaciones/listaConductores";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "api/hidrocarburos/postulaciones/listaRemolques";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/hidrocarburos/postulaciones/listaVehiculos";
      }

      let params = {
        entidad,
        dato,
        fecha: this.postulaciones.solicitud_diaria.fecha,
        sitio_id: this.postulaciones.solicitud_diaria.sitio.id,
        tipo_distancia: 1,
      };

      var res = null;
      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    back() {
      if (this.pendientes != 0 && this.postulaciones.estado === 1) {
        this.$swal({
          title: "Aún no ha realizado todas las postulaciones!",
          text: "¿Está seguro que desea volver?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, volver!",
        }).then((result) => {
          if (result.value) {
            return this.$router.replace("/Hidrocarburos/Postulaciones");
          }
        });
      } else {
        return this.$router.replace("/Hidrocarburos/Postulaciones");
      }
    },

    limpiarForm() {
      this.idActualizar = null;
      this.formActualizar = null;
      this.file = null;
    },

    async verFormActualizar(id, det, postulacion) {
      det.adicional = postulacion.adicional;
      this.file = null;
      //console.log(det);
      let resvig = await this.validarVigencia(det);

      if (resvig == 2 && det.id !== null) {
        this.idActualizar = id;
        this.formActualizar = det;
      } else if (resvig == 1) {
        this.idActualizar = id;
        this.formActualizar = det;
      } else {
        this.$swal({
          icon: "error",
          title: "No puede agregar ni modificar la postulación",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    async arreglarForm(det, index) {
      this.falgValidando = true;

      let form = {
        id: det.id,
        det_solicitud_diaria_id: det.det_solicitud_diaria_id,
        vehiculo_id: this.formActualizar.vehiculo.id,
        /*         remolque_id: this.formActualizar.remolque
          ? this.formActualizar.remolque.id
          : null, */
        conductor_id: this.formActualizar.conductor.id,
        estado: 1,
        orden_cargue: this.formActualizar.orden_cargue,
        link_orden_cargue: this.formActualizar.link_orden_cargue,
      };

      let resSave = await this.save(form);

      if (resSave !== null) {
        this.formActualizar.id = resSave.id;
        this.formActualizar.link_orden_cargue = resSave.link_orden_cargue;
        this.det_postulaciones.find(
          (post) => post.id_det_solicitud === det.det_solicitud_diaria_id
        ).data[index] = this.formActualizar;

        this.postulaciones.solicitud_diaria.det_solicitudes_diarias
          .find((post) => post.id === det.det_solicitud_diaria_id)
          .det_postulaciones.push(this.formActualizar);

        await this.limpiarForm();
      }

      this.falgValidando = false;
    },

    async save(form) {
      let formData = new FormData();
      formData.append("id", form.id);
      formData.append("det_solicitud_diaria_id", form.det_solicitud_diaria_id);
      formData.append("vehiculo_id", this.formActualizar.vehiculo.id);
      formData.append(
        "remolque_id",
        this.formActualizar.remolque ? this.formActualizar.remolque.id : null
      );
      formData.append("conductor_id", this.formActualizar.conductor.id);
      formData.append("estado", 1);
      formData.append("orden_cargue", this.formActualizar.orden_cargue);
      formData.append("link_orden_cargue", this.file);
      formData.append(
        "link_orden_cargue_text",
        this.formActualizar.link_orden_cargue
      );
      var res = false;
      await axios
        .post("api/hidrocarburos/postulaciones", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (response) => {
          if (response.data.error !== undefined) {
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            await this.barraPorcentaje();
            res = response.data;
            this.$swal({
              icon: "success",
              title: "La postulación se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          res = form;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async destroy(det) {
      let resvig = await this.validarVigencia(det);
      let status = false;
      if (resvig == 2 && det.id !== null) {
        status = true;
      } else if (resvig == 1) {
        status = true;
      } else {
        this.$swal({
          icon: "error",
          title: "No puede agregar ni modificar la postulación",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }

      if (status) {
        this.$swal({
          title: "Está seguro de eliminar esta postulación?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Eliminar!",
        }).then((result) => {
          if (result.value) {
            axios
              .delete("/api/hidrocarburos/postulaciones/" + det.id)
              .then(() => {
                this.getIndex();
                this.$swal({
                  icon: "success",
                  title: "Se eliminó la postulación exitosamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
              });
          }
        });
      }
    },

    elegirDirectorio() {
      let vm = this;
      vm.file = vm.$refs.file[0].files[0];
    },

    enviarCorreo() {
      axios
        .get("/api/hidrocarburos/postulaciones/enviarCorreo", {
          params: {
            solicitud_diaria_id: this.$route.params.solicitud_diaria_id,
            empresa_id: this.$route.params.empresa_id,
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Correo enviado satisfactoriamente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    diasBack() {
      axios.get("/api/fecha").then((response) => {
        //console.log(response.data);
        this.hoy = response.data.hoy;
        this.manana = response.data.manana;
      });
    },
  },

  beforeMount() {
    this.getIndex();
    this.diasBack();
  },
};
</script>
